import React from "react";
import { AutomationIcon, AllVideosIcon, PlayIcons, CloseIcon } from "./Icons";
import axios from "axios";

const fetchImg = name => {
  try {
    const img = require(`./images/webinar/${name}.png`);
    return img;
  } catch (error) {
    const img = require(`./images/webinar/default.png`);
    return img;
  }
};

const App = () => {
  const [video, setVideo] = React.useState([]);

  const [subscribed, setSubcribed] = React.useState(false);

  const [currentList, setCurrentList] = React.useState("overview");

  const [currentVideo, setCurrentVideo] = React.useState(null);

  const openModel = data => setCurrentVideo(data);

  React.useEffect(() => {
    axios
      .get("/videos/list")
      .then(res => res.data)
      .then(data => {
        setVideo(data);
      });
  }, []);

  const MenuLists = [
    {
      icon: <AllVideosIcon />,
      menu: "Overview",
      key: "overview",
      onclick: () => {
        setCurrentList("overview");
      }
    },
    {
      icon: <AutomationIcon />,
      menu: "Automations",
      key: "automation",
      onclick: () => {
        setCurrentList("automation");
      }
    }

    // {
    //   icon: <DialogIcon />,
    //   menu: "Dialogs",

    //   onclick: () => {
    //     setCurrentList("dialogs");
    //   },
    // },
    // {
    //   icon: <IntegrationIcon />,
    //   menu: "Integrations",

    //   onclick: () => {
    //     setCurrentList("integrations");
    //   },
    // },
    // {
    //   icon: <UseCasesIcon />,
    //   menu: "Use cases",

    //   onclick: () => {
    //     setCurrentList("use_cases");
    //   },
    // },
    // {
    //   icon: <KnowledgeBaseIcon />,
    //   menu: "Knowledge base",

    //   onclick: () => {
    //     setCurrentList("knowledge_base");
    //   },
    // },
    // {
    //   icon: <HelpSupportIcon />,
    //   menu: "Help/Support",

    //   onclick: () => {
    //     setCurrentList("support");
    //   },
    // },
  ];

  return (
    <>
      <HeaderSection subscribed={subscribed} setSubcribed={setSubcribed} />
      {subscribed ? (
        <>
          <VideosLists MenuLists={MenuLists} currentList={currentList} />
          <MobileDropdown
            MenuLists={MenuLists}
            currentList={currentList}
            setCurrentList={setCurrentList}
          />
          <YoutubeVideos
            currentList={currentList}
            video={video}
            openModel={openModel}
          />
        </>
      ) : (
        <VideoContainerPage />
      )}

      {currentVideo !== null ? (
        <Modal
          closeModel={() => setCurrentVideo(null)}
          currentVideo={currentVideo}
        />
      ) : null}
    </>
  );
};

const VideoBg = require("../assets/images/video_thumb.jpg");

const HeaderLeftImg = require("./images/videos_page/video_img.png");

const HeaderSection = ({ subscribed, setSubcribed }) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const handleCLick = () => {
    axios.post("/videos/subscribe", { name, email });
    setEmail("");
    setName("");
    setSubcribed(true);
  };
  return (
    <section className="assistant_slider beta-main-slider trial_mb_80 bg_landing_zendesk bg_market_Webinar">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div className="main-slider-left">
              <h1>
                Know more about the innovative capabilities of Workativ
                Assistant and transform your workplace support
              </h1>
              <>
                <p>Subscribe now to watch 40+ videos</p>
                <div className="main-slider-button beta-button">
                  <form name="form">
                    {subscribed === false ? (
                      <>
                        <input
                          type="text"
                          id="beta-name"
                          name="firstname"
                          value={name}
                          disabled={subscribed === true}
                          placeholder="Name"
                          onChange={e => setName(e.target.value)}
                        />
                        <input
                          type="text"
                          id="beta-email"
                          name="lastname"
                          placeholder="Email"
                          value={email}
                          placeholder="Email"
                          disabled={subscribed === true}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </>
                    ) : null}
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      id="register-for-trail"
                      className={
                        email.match(
                          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g
                        ) !== null && name.trim() !== ""
                          ? "btn btn-primary"
                          : "btn btn-primary disabled_button"
                      }
                      onClick={handleCLick}
                    >
                      {subscribed === true ? "Subscribed" : "Subscribe Now"}
                    </button>
                  </form>
                </div>
              </>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 bg_landing_zendesk_img">
            <div className="main-slider-right assistant_right_img beta-slider">
              <img src={HeaderLeftImg} alt={HeaderLeftImg} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const VideoContainerPage = () => {
  return (
    <section className="webinar_page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="webinar_img_container">
              <img src={VideoBg} alt={VideoBg} />
              <span className="para_center_videos">
                Subscribe now to watch 40+ videos
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const VideosLists = ({ MenuLists, currentList }) => {
  return (
    <section className="video_lists">
      <div className="video_lists_lg">
        <div className="container video_lists_container">
          <ul className="video_list_ul">
            {MenuLists.map(data => (
              <li
                className={
                  currentList === data.key
                    ? "video_list_li video_list_li_active"
                    : "video_list_li"
                }
                onClick={e => data.onclick()}
              >
                <div className="video_svg_iocn">{data.icon}</div>
                <div className="video_content_li">{data.menu}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

const YoutubeVideos = ({ currentList, video, openModel }) => {
  const renderItems =
    currentList === "all"
      ? video
      : video.filter(e => e.category === currentList);

  return (
    <section className="utube_videos">
      <div className="container">
        <div className="utube_rows">
          {renderItems
            .map(e => ({
              ...e,
              url: e.url.replace("watch?v=", "embed/")
            }))
            .map((data, index) => {
              return (
                <div className="utube_col_4" key={index}>
                  <div className="utube_iframe" onClick={e => openModel(data)}>
                    <span>
                      <PlayIcons />
                    </span>
                    <img src={fetchImg(data.icon)} />
                  </div>
                  <div className="utube_content">
                    <p>{data.title}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export const Modal = ({ currentVideo, closeModel }) => {
  return (
    <div className="modal_popup modal_show">
      <div className="modal_popup_video">
        <div className="video_iframe">
          <iframe
            src={currentVideo.url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          ></iframe>
          <span onClick={e => closeModel()}>
            <CloseIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

//Dropdown for Mobile
export const MobileDropdown = ({ MenuLists, currentList }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const currentvalue = MenuLists.find(e => e.key === currentList);
  return (
    <section className="mobile_dropdown">
      <div className="container">
        <div className="select_application">
          <div className="select_work_form" onClick={e => setIsOpen(!isOpen)}>
            <h4>{currentvalue ? currentvalue.menu : currentList}</h4>
            <input
              type="text"
              readOnly
              value={currentvalue ? currentvalue.menu : currentList}
              onChange={e => {}}
            />
            <div className="arrow_up_form">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.75">
                <title />
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M5,5.75H5a.78.78,0,0,1-.53-.21L.22,1.29a.74.74,0,0,1,0-1,.75.75,0,0,1,1,0L5,4,8.74.22a.74.74,0,0,1,1,1L5.51,5.54A.78.78,0,0,1,5,5.75Z" />
                  </g>
                </g>
              </svg>
            </div>
            {isOpen == true ? (
              <div
                className="automation_scroly"
                role="combobox"
                aria-expanded="true"
                aria-haspopup="listbox"
                aria-owns="downshift-5-menu"
                aria-labelledby="downshift-5-label"
              >
                <ul>
                  {MenuLists.map(data => (
                    <li
                      onClick={e => {
                        setIsOpen(false);
                        data.onclick();
                      }}
                    >
                      {data.menu}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export const VideoApp = App;

// ReactDOM.render(<App />, document.getElementById("app-root"));
