/*eslint-disable*/
import React from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import { VideoApp } from "../../react/videos";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        description="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations"
        ]}
        ogTitle="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        ogDescription="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <VideoApp {...props} />
        </Layout>
      </Container>
    </>
  );
}
